.rowProduct {
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
  flex-flow: row nowrap;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rowProduct::-webkit-scrollbar {
  display: none;
}

.input_number {
  -webkit-font-smoothing: antialiased;
  box-sizing: inherit;
  font-family: sans-serif;
  margin: 0;
  overflow: visible;
  color: rgb(49, 53, 59);
  width: 66px;
  text-align: center;
  margin-bottom: 0px;
  border-top-width: initial;
  border-right-width: initial;
  border-left-width: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  box-shadow: none;
  line-height: 22px;
  font-size: 16px;
  padding: 1px;
  border-style: none none solid;
  border-image: initial;
  border-bottom: 1px solid rgb(229, 231, 233);
  border-radius: 0px;
  outline: none;
  background: transparent;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.input_text {
  -webkit-font-smoothing: antialiased;
  box-sizing: inherit;
  font-family: sans-serif;
  margin: 0;
  color: rgb(49, 53, 59);
  width: 100%;
  border-top-width: initial;
  border-right-width: initial;
  border-left-width: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  box-shadow: none;
  line-height: 22px;
  font-size: 16px;
  padding: 1px;
  border-style: none none solid;
  border-image: initial;
  border-bottom: 1px solid rgb(229, 231, 233);
  border-radius: 0px;
  outline: none;
  background: transparent;
}