.outerContainer {
  width: 100%;
  height: 100%;
  /* background-color: #3E4551; */
  /* background-color: #b2ff59; */
  /* background-image: linear-gradient(to right, #ffc3a0, #ffafbd); */
}

.container {
  padding-bottom: 6rem;
  padding-top: 20vh;
}

.logoContainer {
  text-align: center;
  margin-top: -8%;
  margin-bottom: -8%;
}

.logo {
  width: 60%;
  text-align: center;
}

.card {
  height: 100%;
  padding: 20px;
  margin: 0 auto;
  max-width: calc(410px + 4rem);
  /* margin-top: 10%;
    margin-bottom: 10%; */
}

.title {
  margin: 20px;
  padding-left: 10px;
  font-weight: 600;
  border-bottom: 1px solid black;
}

.sub_title {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 10px;
  font-weight: 400;
  border-bottom: 1px solid black;
}

.innerContainer {
  /* margin: 20px; */
}

.checkRememberMe {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}

.forgotPassword {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: right;
}

.submitContainer {
  margin-top: 5%;
  margin-bottom: 5%;
}

.toRegister {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
