.container{
    padding: 5px;
}

.cardBody{
    padding: 0;
}

.title{
    text-align: center;
    font-weight: 400;
    color: black;
    letter-spacing: 0;
    box-sizing: border-box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    white-space: normal;
    overflow-wrap: break-word;
    font-size: 14px;
}

.titleToCategory{
    text-align: center;
    font-weight: 500;
    letter-spacing: 0;
    box-sizing: border-box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    white-space: normal;
    overflow-wrap: break-word;
    font-size: 14px;
}

.cardFooter{
    padding: 0;
}

.price{
    font-size: 14px;
    padding: 0;
    text-align: center;
    font-weight: 800;
    letter-spacing: 0;
    box-sizing: border-box;
    white-space: normal;
    overflow-wrap: hidden;
}