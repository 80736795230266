#single2 {
  background-color: #fff
}

#single2 .navbar.scrolling-navbar {
  background-color: #1f1e1c;
}

#icon-cart {
  padding: 10;
}
