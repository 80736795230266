.imageOuterContainer {
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  margin: 0;
}

.imageInnerContainer {
  width: 195px;
  /* height: 300px; */
  /* border: 1px solid lightgray; */
  margin-left: 0.35rem;
  margin-right: 0.35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  flex: none;
}

/* .img {
  max-width: 125px;
  max-height: 125px;
} */

.btnSubmit {
  text-align: center;
}
