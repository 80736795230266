.containerCategory {
  /* height: 84px;
  padding: 6px;
  width: 84px;
  position: relative;
  display: inline-block; */
  /* float: none; */
  /* box-sizing: content-box; */
}

.containerCategoryLink {
  text-align: center;
  /* line-height: normal; */
  /* border-radius: 8px; */
  /* position: absolute; */
  /* width: 100%; */
}

.containerImage {
  height: 62px;
  transform: scale(calc(5 / 6));
  display: block;
  position: static;
}

.images {
  display: inline-block;
  height: 64px;
  vertical-align: top;
  width: 64px;
}
