.outerContainer {
  width: 100%;
  height: 100%;
  /* background-color: #3E4551; */
  /* background-color: #b2ff59; */
  background-image: linear-gradient(to right, #ffc3a0, #ffafbd);
}

.container {
  padding-top: 5%;
  padding-bottom: 5%;
}

.card {
  height: 100%;
  padding: 20px;
  /* margin-top: 10%;
  margin-bottom: 10%; */
}

.title {
  margin: 20px;
  padding-left: 10px;
  font-weight: 600;
  border-bottom: 1px solid black;
}

.sub_title {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 10px;
  font-weight: 400;
  border-bottom: 1px solid black;
}

.innerContainer {
  /* margin: 20px; */
}

.submitContainer {
  margin-top: 5%;
  margin-bottom: 5%;
}

/* .toLogin {
  text-align: right;
  color: white;
} */
